<template>
  <q-btn-toggle
    v-model="selectedPanel"
    spread
    no-caps
    toggle-color="primary"
    color="info"
    :options="panels"
    :disable="readonly"
  />
  <q-tab-panels
    v-model="selectedPanel"
    animated
    transition-prev="fade"
    transition-next="fade"
  >
    <q-tab-panel v-for="(product, key) in products" :key="key" :name="key">
      <q-markup-table flat>
        <thead>
          <tr class="text-weight-bold">
            <th class="text-center" style="font-weight: bold !important">
              Aanbieder
            </th>
            <th class="text-center" style="font-weight: bold !important">
              Product
            </th>
            <th class="text-center" style="font-weight: bold !important">
              Details
            </th>
            <th class="text-center" style="font-weight: bold !important"></th>
            <th class="text-center" style="font-weight: bold !important"></th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(value, key) in product"
            :key="key + selectedProducts.length"
            :style="
              findSelectedProduct(key, value.type)
                ? 'background-color: rgb(240, 255, 240); transition: 0.2s;'
                : 'transition: 0.2s;'
            "
            v-show="showProduct(value, value.type)"
          >
            <energy-row
              v-if="value.type == 'energy'"
              :product_value="value"
              :product_key="key"
            />
            <charity-row v-else :product_value="value" :product_key="key" />
          </tr>
        </tbody>
      </q-markup-table>
    </q-tab-panel>
  </q-tab-panels>
</template>

<script setup>
import { ref, computed } from "@vue/reactivity";
import { useStore } from "vuex";
import EnergyRow from "./Rows/EnergyRow.vue";
import charityRow from "./Rows/CharityRow.vue";

//* VueUse
const $store = useStore();

//* Refs
const selectedPanel = ref("");

//* Computed
const panels = computed(() => $store.getters?.products?.options ?? false);
const products = computed(() => $store.getters.products.products);
const selectedProducts = computed(() => $store.getters.selectedProducts ?? []);
const projectSpecific = computed(() => $store.getters.projectSpecific);
const readonly = computed(
  () => ($store.getters.record?.locked || $store.getters.viewMode) ?? false
);

//*  Methods
function showProduct(value, type) {
  if (type !== "energy") return true;

  return (
    (value.contract == "Gas en Elektriciteit" &&
      (!projectSpecific.value["MONO"] ||
        (projectSpecific.value["EAN Elektra"] &&
          projectSpecific.value["EAN Gas"]))) ||
    (value.contract == "Elektriciteit" &&
      (projectSpecific.value["MONO"] ||
        (projectSpecific.value["EAN Elektra"] &&
          !projectSpecific.value["EAN Gas"]))) ||
    (value.contract == "Gas" &&
      (projectSpecific.value["MONO"] ||
        (!projectSpecific.value["EAN Elektra"] &&
          projectSpecific.value["EAN Gas"])))
  );
}

function findSelectedProduct(key) {
  return selectedProducts.value.find((arr) => arr.product_id == key);
}
</script>
<style lang="sass"></style>
