import script from "./PanelProductSelection.vue?vue&type=script&setup=true&lang=js"
export * from "./PanelProductSelection.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QBtnToggle from 'quasar/src/components/btn-toggle/QBtnToggle.js';
import QTabPanels from 'quasar/src/components/tab-panels/QTabPanels.js';
import QTabPanel from 'quasar/src/components/tab-panels/QTabPanel.js';
import QMarkupTable from 'quasar/src/components/markup-table/QMarkupTable.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBtnToggle,QTabPanels,QTabPanel,QMarkupTable});
